import React from 'react'

import ReactMarkdown from 'react-markdown'

const BlogPost = ({ post }) => {

  const date = new Date(post.frontmatter.date)

  return (
    <div className='p-4 md:p-8 glass'>
      <h2 className='mb-2 text-xl md:text-4xl'>{post.frontmatter.title}</h2>
      <h3 className='mb-6 text-gray-400'>{date.toLocaleDateString()} {date.toLocaleTimeString()}</h3>
      <ReactMarkdown>
        {post.rawMarkdownBody}
      </ReactMarkdown>
    </div>
  )
}

export default BlogPost
