import React from 'react'

import { graphql } from 'gatsby'

import { MotionConfig } from 'framer-motion'
import { Helmet } from 'react-helmet'

import Header from '../components/Header'
import BlogPost from '../components/blog/BlogPost'

const blog = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  console.log(posts)

  return (
    <MotionConfig reducedMotion='user'>
      <Helmet>
        <title>Cole Zesiger - Blog</title>
        <meta
          name='description'
          content='Cole Zesiger helps couples create happier and deeper relationships.'
        />
      </Helmet>
      <div className='pt-12'>
        <div className='container px-8 mx-auto'>
          <Header />
          {posts.map((post) => (
            <BlogPost key={post.id} post={post} />
          ))}
        </div>
      </div>
    </MotionConfig>
  )
}

export default blog

export const query = graphql`
  query BlogPosts {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(content/blog)/" } }
    ) {
      nodes {
        id
        rawMarkdownBody
        frontmatter {
          date
          tags
          title
        }
      }
    }
  }
`
